import imgPlaceholder from "../assets/images/image_placeholder.png";
import { Table, Typography } from "../components/atoms";
import Card from "../components/atoms/card";

const Portfolio = () => {
  const FAKE_DATA_TABLE_TOP = [
    [
      /* {
        type: "clickable-line",
        value: "",
        action: () => handleModal("ABCD"),
      }, */
      {
        type: "image-text",
        value: { img: imgPlaceholder, text: "ABCD" },
      },
      {
        type: "text",
        value: "13.45%",
      },
      /*  {
        type: "tooltip-text",
        value: {
          text: "0.65%",
          tooltip: (
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-6">
                <Typography>0.67%</Typography>
                <div className="w-[30px] h-[30px]">
                  <img src={imgPlaceholder} alt="" />
                </div>
              </div>
              <div className="flex items-center gap-6">
                <Typography>0.67%</Typography>
                <div className="w-[30px] h-[30px]">
                  <img src={imgPlaceholder} alt="" />
                </div>
              </div>
            </div>
          ),
        },
      }, */
      {
        type: "text",
        value: "0.00 ABCD",
      },
      {
        type: "switch",
        value: {
          active: false,
          onToggleOn: () => console.log("selected collateral: prova"),
        },
      },
    ],
  ];

  return (
    <>
      <div className="flex flex-col gap-6">
        <Typography size="xl" weight="semibold">
          Portfolio:
        </Typography>
        <div className="flex gap-6 w-full">
          <div className="flex justify-between w-full">
            <div className="flex flex-col gap-2">
              <Typography>Total value locked</Typography>
              <Typography size="xl">192.419.421 $</Typography>
            </div>
            <div className="flex flex-col gap-2">
              <Typography>Total value locked</Typography>
              <Typography size="xl">192.419.421 $</Typography>
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex flex-col gap-2">
              <Typography>Total value locked</Typography>
              <Typography size="xl">192.419.421 $</Typography>
            </div>
            <div className="flex flex-col gap-2">
              <Typography>Total value locked</Typography>
              <Typography size="xl">192.419.421 $</Typography>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center gap-4 box-border">
          <div className="flex flex-col gap-4 w-full">
            <Typography size="xl" weight="semibold">
              Your Supplied Assets
            </Typography>

            {/*  <Input onChange={handleSearchLeft} iconLeft={<SearchIcon />} /> */}
            <Card>
              <Table
                titles={["asset", "supply", "wallet", "collateral"]}
                values={FAKE_DATA_TABLE_TOP}
                emptyMessage="You have no supplied assets"
              />
            </Card>
          </div>
          <div className="flex flex-col gap-4 w-full box-border">
            <Typography size="xl" weight="semibold">
              Your Borrowed Assets
            </Typography>
            {/*   <Input onChange={handleSearchRight} iconLeft={<SearchIcon />} /> */}
            <Card>
              <Table
                titles={["asset", "supply", "wallet"]}
                values={[]}
                emptyMessage="You have no borrowed assets"
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
