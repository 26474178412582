import { useQuery } from "@tanstack/react-query";
import useWalletData from "./useWalletData";
import axios from "axios";
import { BACKEND_URL } from "../../envs";
import { useRdtProvider } from "../providers/RdtProvider";
import { Decimal, UserLimits } from "../../types/types";
import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import { ZERO_DECIMAL } from "../../constants";

type UseLimitsOptions = {
  simulateLimit: {
    addCollateral?: {
      address: string;
      amount: Decimal;
    }[];
    borrow?: {
      address: string;
      amount: Decimal;
    }[];
    repay?: {
      cdp: string;
      address: string;
      amount: Decimal;
    }[];
    removeCollateral?: {
      address: string;
      amount: Decimal;
    }[];
  };
};

export default function useLimits(options?: UseLimitsOptions) {
  const { address } = useRdtProvider();
  const { data: walletData } = useWalletData();

  return useQuery({
    enabled: walletData != null,
    queryKey: ["stats", walletData, address, options],
    queryFn: async ({ signal }) => {
      if (!walletData) return;

      const supplies = walletData.supplies
        .filter((supply) => !supply.isRtToken)
        .map((supply) => ({
          address: supply.address,
          amount: supply.asset_amount.toDecimalPlaces(36),
        }));

      const cdps = walletData.nfts
        .map((nft) =>
          nft.collateral.resources.map((collateral) => ({
            address: collateral.token.address,
            amount: collateral.asset_amount.toDecimalPlaces(36),
          }))
        )
        .flat();

      const borrows = walletData.nfts
        .map((nft) =>
          nft.loan.map((loan) => ({
            address: loan.token.address,
            amount: loan.asset_amount.toDecimalPlaces(36),
          }))
        )
        .flat();

      if (options?.simulateLimit.addCollateral) {
        for (const newCollateral of options.simulateLimit.addCollateral) {
          for (let i = 0; i < supplies.length; i++) {
            if (supplies[i].address === newCollateral.address) {
              supplies[i].amount = supplies[i].amount
                .sub(newCollateral.amount)
                .toDecimalPlaces(36);
            }
          }

          const existingCdp = cdps.find(
            (cdp) => cdp.address === newCollateral.address
          );

          if (!!existingCdp) {
            existingCdp.amount = preciseDecimal(existingCdp.amount || 0)
              .value.add(newCollateral.amount)
              .toDecimalPlaces(36);
          } else {
            cdps.push({
              address: newCollateral.address,
              amount: newCollateral.amount.toDecimalPlaces(36),
            });
          }
        }
      }

      if (options?.simulateLimit.borrow) {
        options.simulateLimit.borrow.forEach((borrow) => {
          borrows.push(borrow);
        });
      }

      if (options?.simulateLimit.repay) {
        for (const repay of options.simulateLimit.repay) {
          for (let i = 0; i < borrows.length; i++) {
            if (borrows[i].address === repay.address) {
              borrows[i].amount = preciseDecimal(borrows[i].amount)
                .value.sub(repay.amount)
                .toDecimalPlaces(36);
            }
          }
        }
      }

      if (options?.simulateLimit.removeCollateral) {
        for (const collateral of options.simulateLimit.removeCollateral) {
          for (let i = 0; i < cdps.length; i++) {
            if (cdps[i].address === collateral.address) {
              cdps[i].amount = (
                preciseDecimal(cdps[i].amount)
                  .value.sub(collateral.amount)
                  .gt(0)
                  ? preciseDecimal(cdps[i].amount).value.sub(collateral.amount)
                  : ZERO_DECIMAL
              ).toDecimalPlaces(36);
              break;
            }
          }
        }
      }

      const response = await axios.post<UserLimits>(
        `${BACKEND_URL}/api/markets/health-bar`,
        {
          supplies,
          cdps,
          borrows,
        },
        {
          signal,
        }
      );

      return response.data;
    },
    refetchInterval: 30000,
  });
}
