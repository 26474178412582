import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BACKEND_URL } from "../../envs";
import useWalletData from "./useWalletData";
import { Decimal } from "../../types/types";
import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";

export default function useWidthdrawLimits({
  address,
  amount,
}: {
  address: string;
  amount: Decimal;
}) {
  const { data: walletData } = useWalletData();
  return useQuery({
    enabled: walletData?.nfts != null && !amount.equals(0),
    queryKey: ["widthdraw-limit", walletData?.nfts, address, amount],
    queryFn: async ({ signal }) => {
      if (!walletData) {
        throw new Error("wallet data not found");
      }

      const cdps = walletData.nfts
        .map((nft) =>
          nft.collateral.resources.map((collateral) => ({
            address: collateral.token.address,
            amount: collateral.asset_amount,
          }))
        )
        .flat();

      const borrows = walletData.nfts
        .map((nft) =>
          nft.loan.map((loan) => ({
            address: loan.token.address,
            amount: loan.asset_amount,
          }))
        )
        .flat();

      const response = await axios.post<{ maxWithdrawableToken: Decimal }>(
        `${BACKEND_URL}/api/markets/max-withdrawable-token`,
        {
          cdps,
          borrows,
          withdraw_asset: {
            address,
            amount,
          },
        },
        {
          signal,
        }
      );

      return preciseDecimal(response.data.maxWithdrawableToken)?.value;
    },
    refetchInterval: 30000,
  });
}
