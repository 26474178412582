export type HealthBarProgressProps = {
  value: number;
  total: number;
};

const HealthBarProgress = ({ value, total }: HealthBarProgressProps) => (
  <div
    className={`relative w-full min-w-[300px] h-[8px] rounded-md bg-gradient-to-r from-75% from-[#bde93f] via-90% via-[#db7f44]  to-[#fe0449]   overflow-hidden`}
  >
    <div
      className={`absolute right-0 top-0 h-full bg-black `}
      style={{
        width: !total ? "100%" : `${100 - (value * 100) / total}%`,
      }}
    />
  </div>
);

export default HealthBarProgress;
