import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TOKENS } from "../../constants";
import { getToken } from "../../utils";
import { BACKEND_URL } from "../../envs";
import { PoolState, ProtocolData } from "../../types/types";
import { getPrices } from "./usePrices";

const useProtocolData = () => {
  return useQuery({
    queryKey: ["get_protocol_data"],
    queryFn: async () => {
      const [statsSettled, poolStateSettled, pricesSettled] =
        await Promise.allSettled([
          axios.get<ProtocolData>(`${BACKEND_URL}/api/markets/stats`, {
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "true",
            },
          }),
          axios.get<PoolState>(`${BACKEND_URL}/api/markets/pool-state`),
          getPrices(),
        ]);

      const stats =
        statsSettled.status === "fulfilled"
          ? statsSettled.value.data
          : undefined;
      const poolState =
        poolStateSettled.status === "fulfilled"
          ? poolStateSettled.value.data
          : undefined;

      const prices =
        pricesSettled.status === "fulfilled" ? pricesSettled.value : undefined;

      const tokens = Object.entries(TOKENS).map(([address]) => {
        return getToken(address, stats, poolState, prices);
      });

      return { stats: stats, tokens };
    },
    refetchInterval: 30000,
  });
};

export default useProtocolData;
