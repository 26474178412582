import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BACKEND_URL } from "../../envs";

const useRootPoints = () => {
  return useQuery({
    enabled: false,
    queryKey: ["get_root_points"],
    queryFn: async () => {
      const res = await axios.get<number>(
        `${BACKEND_URL}/api/markets/root_points`,
        {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      return res?.data;
    },
    refetchInterval: 30000,
  });
};

export default useRootPoints;
