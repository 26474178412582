import { useEffect, useMemo, useState } from "react";
import Input from "../atoms/input";
import Typography from "../atoms/typography";
import debounce from "lodash.debounce";
import { Decimal } from "../../types/types";
import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import { ZERO_DECIMAL } from "../../constants";
import { Button } from "../atoms";
import { formatNumbers } from "../../utils";

export interface SliderProps {
  mini?: boolean;
  light?: boolean;
  maxValue: Decimal;
  onChange: (e?: any) => void;
  onLoadChange: (e?: any) => void;
  max?: boolean;
}

const SliderInput = ({
  mini,
  light,
  maxValue,
  onChange,
  onLoadChange,
  max,
}: SliderProps) => {
  const values = [0, 25, 50, 75, 100];

  const [value, setValue] = useState<Decimal>(ZERO_DECIMAL);
  const [showValue, setShowValue] = useState<string>("");

  const updateBothInputs = (value: Decimal) => {
    setValue(value);

    setShowValue(
      value.toString() === "0"
        ? ""
        : formatNumbers(value.toString()).replace(",", "")
    );
  };

  const debounceFn = useMemo(
    () =>
      debounce((inputValue: Decimal | string, prev: Decimal | string) => {
        let numberInput = ZERO_DECIMAL;
        if (typeof inputValue === "string") {
          try {
            numberInput = preciseDecimal(inputValue).value;
          } catch {
            setShowValue(
              prev.toString() === "0"
                ? ""
                : formatNumbers(prev.toString()).replace(",", "")
            );

            onLoadChange(false);
            return;
          }
        } else {
          numberInput = inputValue;
        }

        if (numberInput.lessThanOrEqualTo(ZERO_DECIMAL)) {
          updateBothInputs(ZERO_DECIMAL);
          onLoadChange(false);
          onChange(ZERO_DECIMAL);
          return;
        }

        if (numberInput.greaterThan(maxValue)) {
          updateBothInputs(maxValue);
          onLoadChange(false);
          onChange(maxValue);
          return;
        }

        //round 99.95% to 100% of value if previous value is lower (user is increasing slider's value)
        const ratio = preciseDecimal(0.9995).value;
        if (
          numberInput.div(maxValue).greaterThan(ratio) &&
          typeof prev !== "string" &&
          prev?.lessThan(numberInput)
        ) {
          updateBothInputs(maxValue);
          onLoadChange(false);
          onChange(maxValue);
          return;
        }

        if (typeof inputValue === "string") {
          setValue(numberInput);
        }

        onLoadChange(false);
        onChange(numberInput);
      }, 1000),
    [maxValue, onChange, onLoadChange]
  );

  const handleInputChange = (inputValue: string, prev: Decimal) => {
    if (inputValue.includes(",")) {
      return;
    }

    setShowValue(inputValue);
    onLoadChange(true);
    debounceFn(inputValue, prev);
  };

  const handleSliderChange = (inputValue: Decimal, prev: Decimal) => {
    updateBothInputs(inputValue);
    onLoadChange(true);
    debounceFn(inputValue, prev);
  };

  useEffect(() => {
    return () => {
      debounceFn.cancel();
    };
  }, [debounceFn]);

  return (
    <div className="flex flex-col w-full gap-6 px-4 pt-4 pb-10">
      <div className="flex items-center w-full gap-2">
        <Input
          type="text"
          value={showValue}
          onChange={(val) => handleInputChange(val, value)}
        />
        {max && (
          <Button
            label="Max"
            variant="max"
            action={() => handleSliderChange(maxValue, value)}
          />
        )}
      </div>

      <div
        className={`py-2 w-full h-fit ${
          mini ? "min-w-[150px]" : "min-w-[350px]"
        }`}
      >
        <div className="relative h-[2px] bg-slate-500 w-full">
          {values.map((val, i) => {
            return (
              <div
                key={`progress-${i}`}
                className={`absolute top-px ${
                  i !== 0 && values.length !== i + 1 ? "-translate-x-[50%]" : ""
                } -translate-y-[50%] rounded-full border-2 border-textTertiary bg-textSecondary`}
                style={{
                  [values.length === i + 1 ? "right" : "left"]:
                    values.length === i + 1 ? 0 : `${val}%`,
                  top: 1,
                  width: mini ? 6 : 9,
                  height: mini ? 6 : 9,
                }}
              />
            );
          })}

          <div className="relative range-input">
            <input
              onChange={(e: any) =>
                handleSliderChange(
                  preciseDecimal(Number(e.target.value)).value.toDecimalPlaces(
                    36
                  ),
                  value
                )
              }
              type="range"
              min={0}
              step={1 / 10 ** 36}
              max={Number(maxValue.toDecimalPlaces(36))}
              value={(maxValue.equals(0) ? ZERO_DECIMAL : value).toString()}
              className={`range-min absolute w-full top-px -translate-y-[50%] bg-transparent appearance-none cursor-pointer ${
                mini ? "h-[10px]" : "h-[15px]"
              }`}
            />
          </div>

          {values.map((val, i) => (
            <Typography
              key={`percent-${i}`}
              className={`absolute top-[15px] opacity-[60%]`}
              style={{
                left: `calc(${val}% - 7px)`,
              }}
              variant={light ? "primary" : "secondary"}
              size={mini ? "xs" : "md"}
            >
              {val}%
            </Typography>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderInput;
