import { UserLimits } from "../../types/types";
import { formatNumbers } from "../../utils";
import { Card, Typography } from "../atoms";
import HealthBarProgress from "../atoms/HealthBarProgress";
import Spinner from "../atoms/Spinner";

type HealthBarProps = {
  limits: UserLimits | undefined;
  isLoading: boolean;
};

export default function HealthBar({ limits, isLoading }: HealthBarProps) {
  let percent100 = 0;
  if (limits?.liquidation != null && limits.liquidation !== 0) {
    percent100 = (1 / limits.liquidation) * 100;
  }

  return (
    <div className="box-border flex justify-center w-full gap-4 ">
      <div className="flex flex-col w-full gap-4">
        <Card className="overflow-auto min-h-32">
          {isLoading ? (
            <div className="flex items-center justify-center h-full mx-auto w-min">
              <Spinner />
            </div>
          ) : limits ? (
            <div className="grid lg:grid-cols-[70%_30%] grid-cols-1 gap-y-10 py-4 px-6 w-full">
              <div className="flex gap-10 grow">
                <div className="flex flex-col gap-2">
                  <Typography size="md" weight="normal">
                    Your Net APY
                  </Typography>
                  <Typography size="md" weight="semibold">
                    {formatNumbers(limits?.netApyPercentage)}%
                  </Typography>
                </div>
                <div className="flex flex-col gap-2">
                  <Typography size="md" weight="normal">
                    Your Tot Supplies
                  </Typography>
                  <Typography size="md" weight="semibold">
                    ${formatNumbers(limits.totalSupply)}
                  </Typography>
                </div>
                <div className="flex flex-col gap-2">
                  <Typography size="md" weight="normal">
                    Your Tot Borrows
                  </Typography>
                  <Typography size="md" weight="semibold">
                    ${formatNumbers(limits.totalBorrow)}
                  </Typography>
                </div>
              </div>

              <div className="flex pb-6 pr-4 grow gap-x-10 w-fit h-fit">
                <div
                  className={`flex flex-col gap-2 relative ${
                    percent100 < 60 ? "items-end" : "items-start"
                  }`}
                >
                  <div className="flex justify-between gap-8">
                    <div className="flex gap-1">
                      <Typography size="sm" weight="normal">
                        Borrow power used:
                      </Typography>
                      <Typography size="sm" weight="bold">
                        {formatNumbers(limits.borrowLimitUsedPercentage)}%
                      </Typography>
                    </div>
                  </div>

                  <div className="relative">
                    <HealthBarProgress
                      value={limits.totalBorrow}
                      total={limits.totalBorrowLimit * limits.liquidation}
                    />

                    <div
                      className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 whitespace-nowrap"
                      style={{
                        left: `${percent100}%`,
                      }}
                    >
                      <div className="grid place-items-center auto-rows-fr">
                        <Typography size="xs" weight="semibold">
                          100%
                        </Typography>
                        <div className="w-[2px] h-full bg-white rounded-full" />
                        <div className="grid place-items-center">
                          <Typography size="xs" weight="normal">
                            Your Borrow Limit:
                          </Typography>
                          <Typography size="xs" weight="bold">
                            ${formatNumbers(limits.totalBorrowLimit)}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid text-center place-items-center">
                  <Typography size="xs" weight="normal">
                    Liquidation Threshold:
                  </Typography>
                  <Typography size="sm" weight="bold">
                    {Intl.NumberFormat(undefined, { style: "percent" }).format(
                      limits.liquidation
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <p className="flex items-center justify-center w-full h-full text-center text-white">
              No data found
            </p>
          )}
        </Card>
      </div>
    </div>
  );
}
