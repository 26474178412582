import { useState } from "react";
import { redeem, supply } from "../../manifest/manifest";
import { formatNumbers } from "../../utils";
import { Button, Loader, Modal, TxPending, Typography } from "../atoms";
import Spinner from "../atoms/Spinner";
import useConnect from "../hooks/useConnect";
import useLimits from "../hooks/useLimits";
import useWalletData from "../hooks/useWalletData";
import { useRdtProvider } from "../providers/RdtProvider";
import SliderInput from "./sliderInput";
import useWidthdrawLimits from "../hooks/useWithdrawLimits";
import { Decimal, Token } from "../../types/types";
import { ZERO_DECIMAL } from "../../constants";
import { IoIosWarning } from "react-icons/io";
import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";

type SupplyModal = {
  token: Token;
  onClickOutside: (e?: any) => void;
  defaultView: "supply" | "withdraw";
};

//keep 1% margin to avoid withdraw aprox errors
const CORRECTION_FACTOR = preciseDecimal(1.01).value;

const SupplyManageModal = ({
  token,
  onClickOutside,
  defaultView,
}: SupplyModal) => {
  const { rdt } = useRdtProvider();
  const [step, setStep] = useState(defaultView === "supply" ? 0 : 1);

  const [isSliderLoading, setIsSliderLoading] = useState(false);

  const [amount, setAmount] = useState<Decimal>(ZERO_DECIMAL);
  const { address } = useRdtProvider();
  const { mutate: connectWallet, isPending: loading } = useConnect();

  const [txResult, setTxResult] = useState<object | undefined>(undefined);
  const [txLoading, setTxLoading] = useState(false);

  const { data: walletData } = useWalletData();
  const { data: limits } = useLimits();

  const redeemAmount =
    walletData?.nfts
      .map((obj) =>
        obj.collateral.resources.find((t) => token?.address === t.token.address)
      )
      ?.find((supplyToken) => supplyToken?.token.address === token?.address)
      ?.asset_amount || ZERO_DECIMAL;

  const { data: maxWithdrawableToken, isLoading: withdrawLimitsIsLoading } =
    useWidthdrawLimits({
      address: token.address,
      amount: redeemAmount,
    });

  const { data: simulateLimits, isLoading } = useLimits(
    step === 0
      ? {
          simulateLimit: {
            addCollateral: [{ address: token.address, amount: amount }],
          },
        }
      : {
          simulateLimit: {
            removeCollateral: [{ address: token.address, amount: amount }],
          },
        }
  );

  const borrowLimitUsedPercentage =
    (simulateLimits?.borrowLimitUsedPercentage ?? 0) >= 99.95 &&
    (simulateLimits?.borrowLimitUsedPercentage ?? 0) <= 100
      ? 100
      : simulateLimits?.borrowLimitUsedPercentage;

  const cdpId = walletData?.nfts[0]?.collateral.cdp;

  const supplyAmount = walletData?.supplies.find(
    (t) => token?.address === t.address
  )?.asset_amount;

  const suppliedAmount =
    walletData?.nfts[0]?.collateral.resources.find(
      (t) => token?.address === t.token.address
    )?.asset_amount || ZERO_DECIMAL;

  let redeemMax = maxWithdrawableToken ?? ZERO_DECIMAL;
  if (redeemMax.greaterThan(suppliedAmount)) {
    redeemMax = suppliedAmount;
  }

  const availableLiquidity = preciseDecimal(
    token.protocolData?.availableLiquidity ?? 0
  ).value.div(CORRECTION_FACTOR);

  if (redeemMax.greaterThan(availableLiquidity)) {
    redeemMax = availableLiquidity;
  }

  const handleSupply = async () => {
    if (!address) return;
    if (!token) return;

    const manifest = await supply(address, token?.address, amount, cdpId);
    if (!manifest) return;

    setTxLoading(true);

    const result = await rdt.walletApi.sendTransaction({
      transactionManifest: manifest.value as string,
    });

    if (result.isErr()) {
      console.error("TxError", result);
    }
    setTxResult(result);
    setTxLoading(false);
  };

  const handleRedeem = async () => {
    if (!address) return;
    if (!token) return;
    if (!cdpId) return;

    const manifest = await redeem(
      address,
      token?.address,
      amount.mul(token.unitToAssetRatio),
      cdpId
    );
    if (!manifest) return;

    setTxLoading(true);

    const result = await rdt.walletApi.sendTransaction({
      transactionManifest: manifest.value as string,
    });

    if (result.isErr()) {
      console.error("TxError", result);
    }

    setTxResult(result);
    setTxLoading(false);
  };

  return (
    <Modal
      visible
      onClickOutside={
        txLoading || (txResult && !loading)
          ? () => {
              !txLoading && setTxResult(undefined);
              !txLoading && onClickOutside();
            }
          : () => {
              onClickOutside();
              setTxResult(undefined);
            }
      }
    >
      {txLoading || (txResult && !loading) ? (
        <TxPending
          loading={txLoading}
          result={txResult}
          onContinue={() => {
            setTxResult(undefined);
            onClickOutside();
          }}
        />
      ) : (
        <div className="w-full lg:max-w-[500px] flex flex-col gap-6">
          <div className="flex flex-col items-center justify-center w-full gap-2 px-8 py-4">
            <img
              alt={token?.name}
              src={token?.imgPath}
              width={30}
              height={30}
              className="object-cover"
            />

            <Typography
              variant="secondary"
              size="sm"
              weight="semibold"
              className="uppercase"
            >
              {token?.name}
            </Typography>
          </div>

          <div className="flex w-full">
            <Button
              label="Supply"
              variant="secondary"
              action={() => {
                setStep(0);
                setAmount(ZERO_DECIMAL);
              }}
              className={`w-full rounded-none !border-0 transition-all ${
                step === 0
                  ? "border-textSecondary !border-b-[3px] mb-[-3px]"
                  : "border-textSecondary/60 opacity-[60%] !border-b-[2px] mb-[-2px]"
              }`}
            />
            <Button
              label="Withdraw"
              variant="secondary"
              action={() => {
                setStep(1);
                setAmount(ZERO_DECIMAL);
              }}
              className={`w-full rounded-none !border-0 transition-all border- ${
                step === 1
                  ? "border-textSecondary !border-b-[3px] mb-[-3px]"
                  : "border-textSecondary/60 opacity-[60%] !border-b-[2px] mb-[-2px]"
              }`}
            />
          </div>

          <div className="flex flex-col items-center justify-center w-full gap-2 px-8 py-4">
            {step === 0 ? (
              <>
                <div className="flex items-center justify-between w-full gap-4">
                  <Typography
                    className="whitespace-nowrap opacity-[60%]"
                    variant="secondary"
                    size="sm"
                  >
                    Supply amount
                  </Typography>
                  <Typography
                    className="whitespace-nowrap"
                    variant="secondary"
                    size="sm"
                  >
                    Wallet balance amount{" "}
                    {formatNumbers(supplyAmount?.toString() || 0)} {token?.name}
                  </Typography>
                </div>

                {walletData != null ? (
                  <SliderInput
                    key="supplySlider"
                    maxValue={supplyAmount ? supplyAmount : ZERO_DECIMAL}
                    onChange={(value: Decimal) => setAmount(value)}
                    onLoadChange={setIsSliderLoading}
                    max
                  />
                ) : loading ? (
                  <Loader />
                ) : (
                  <button
                    className="w-full cursor-pointer"
                    onClick={() => connectWallet()}
                  >
                    <Typography variant="navbar">
                      Please connect your wallet
                    </Typography>
                  </button>
                )}
              </>
            ) : (
              <>
                <div className="flex items-center justify-between w-full gap-4">
                  <Typography
                    className="whitespace-nowrap opacity-[60%]"
                    variant="secondary"
                    size="sm"
                  >
                    Redeem amount
                  </Typography>
                  <Typography
                    className="flex items-center space-x-2 whitespace-nowrap"
                    variant="secondary"
                    size="sm"
                  >
                    <span>Redeem amount </span>
                    <span>
                      {withdrawLimitsIsLoading ? (
                        <Spinner width={20} height={20} strokeWidth={3} />
                      ) : (
                        formatNumbers(redeemMax.toString()) + " " + token.name
                      )}
                    </span>
                  </Typography>
                </div>

                {walletData != null ? (
                  <SliderInput
                    key="redeemSlider"
                    maxValue={redeemMax}
                    onChange={(value: Decimal) => setAmount(value)}
                    onLoadChange={setIsSliderLoading}
                  />
                ) : loading ? (
                  <Loader />
                ) : (
                  <button
                    className="w-full cursor-pointer"
                    onClick={() => connectWallet()}
                  >
                    <Typography variant="navbar">
                      Please connect your wallet
                    </Typography>
                  </button>
                )}
              </>
            )}

            {walletData != null && (
              <div className="flex flex-col w-full gap-2">
                <Typography
                  variant="navbar"
                  size="lg"
                  className="w-full text-start"
                >
                  Supply Stats
                </Typography>
                <div className="flex flex-col w-full">
                  {token?.protocolData?.lendingAPY != null && (
                    <div className="flex items-center justify-between w-full">
                      <Typography variant="secondary">Supply APY</Typography>
                      <Typography variant="secondary">
                        {formatNumbers(
                          parseFloat(token?.protocolData.lendingAPY)
                        )}
                        %
                      </Typography>
                    </div>
                  )}
                  {supplyAmount != null && (
                    <div className="flex items-center justify-between w-full">
                      <Typography variant="secondary">
                        Supply Balance
                      </Typography>
                      {token.usdPrice ? (
                        <Typography variant="secondary">
                          $
                          {formatNumbers(
                            supplyAmount.mul(token.usdPrice).toString()
                          )}
                        </Typography>
                      ) : (
                        <Typography variant="secondary">
                          {formatNumbers(supplyAmount.toString())} {token?.name}
                        </Typography>
                      )}
                    </div>
                  )}
                </div>

                <Typography
                  variant="navbar"
                  size="lg"
                  className="w-full text-start"
                >
                  Collateral
                </Typography>

                <div className="flex flex-col w-full">
                  {token?.protocolData?.LTVLimit != null && (
                    <div className="flex items-center justify-between w-full">
                      <Typography variant="secondary">MAX LTV</Typography>

                      <Typography variant="secondary">{`${
                        parseFloat(token.protocolData.LTVLimit) * 100
                      }%`}</Typography>
                    </div>
                  )}

                  <div className="flex items-center justify-between w-full">
                    <Typography variant="secondary">
                      Used as collateral
                    </Typography>

                    <Typography variant="secondary">
                      {walletData.nfts.find((nft) =>
                        nft.collateral.resources.some(
                          (res) => res.token.address === token.address
                        )
                      )
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </div>
                </div>

                <Typography
                  variant="navbar"
                  size="lg"
                  className="w-full text-start"
                >
                  Borrow Limit
                </Typography>

                <div className="flex flex-col w-full">
                  <div className="flex items-center justify-between w-full ">
                    <Typography variant="secondary">
                      Your Borrow Limit
                    </Typography>
                    {isLoading || isSliderLoading ? (
                      <Spinner width={20} height={20} strokeWidth={3} />
                    ) : (
                      <Typography variant="secondary">{`$${formatNumbers(
                        limits?.totalBorrowLimit || 0
                      )} -> $${formatNumbers(
                        simulateLimits?.totalBorrowLimit || 0
                      )}`}</Typography>
                    )}
                  </div>

                  <div className="flex items-center justify-between w-full">
                    <Typography variant="secondary">
                      Borrow Limit Used
                    </Typography>
                    {isLoading || isSliderLoading ? (
                      <Spinner width={20} height={20} strokeWidth={3} />
                    ) : (
                      <Typography variant="secondary">
                        <div className="flex items-center gap-1">
                          {`${formatNumbers(
                            limits?.borrowLimitUsedPercentage || 0
                          )}% -> ${formatNumbers(
                            borrowLimitUsedPercentage || 0
                          )}%`}
                          {(borrowLimitUsedPercentage || 0) > 95 ? (
                            <IoIosWarning className="fill-red-500 animate-pulse" />
                          ) : (
                            <></>
                          )}
                        </div>
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            )}

            {supplyAmount != null && (
              <Button
                action={() => {
                  step === 0 ? handleSupply() : handleRedeem();
                }}
                label={step === 0 ? "Supply" : "Withdraw"}
                variant="tertiary"
                className="w-full"
                disabled={
                  isLoading || amount.eq(ZERO_DECIMAL) || isSliderLoading
                }
              />
            )}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default SupplyManageModal;
