import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { useRdtProvider } from "../providers/RdtProvider";

export default function useConnect(
  props?: UseMutationOptions<void, Error, void, unknown>
) {
  const { rdt } = useRdtProvider();

  return useMutation({
    mutationFn: async () => {
      const sendRequestResult = await rdt.walletApi.sendRequest();
      if (sendRequestResult.isErr()) {
        console.error(sendRequestResult.error);
      }

      await new Promise((res, rej) => {
        setTimeout(async () => {
          const updateSharedDataResult = await rdt.walletApi.updateSharedData();
          if (updateSharedDataResult.isErr()) {
            console.error(updateSharedDataResult.error);
            rej();
          }
          res(1);
        }, 500); // Otherwise mobile app would crash
      });
    },
    ...props,
  });
}
