import { ReactNode, useEffect } from "react";

export type ModalProps = {
  children: ReactNode;
  visible: Boolean;
  onClickOutside: (e?: any) => void;
};

const Modal = ({ children, visible, onClickOutside }: ModalProps) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);

  return (
    <div
      className={`fixed left-0 top-0 z-[5000]  flex items-center justify-center bg-cardsBg backdrop-blur cursor-pointer ${
        visible ? "opacity-1 w-screen h-full" : "opacity-0 w-0 h-0"
      }`}
      onClick={() => onClickOutside()}
    >
      <div
        className="w-fit max-h-[90vh] rounded-3xl bg-modalBg shadow-xl cursor-default overflow-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
