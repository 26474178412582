import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BACKEND_URL } from "../../envs";
import { Price } from "../../types/types";

export async function getPrices() {
  const { data } = await axios.get<{
    prices: Array<Price>;
  }>(`${BACKEND_URL}/api/markets/prices`);

  return data.prices;
}

export default function usePrices() {
  return useQuery({
    queryKey: ["rdx-price"],
    queryFn: async () => {
      return await getPrices();
    },
    refetchInterval: 30000,
  });
}
