import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BACKEND_URL } from "../../envs";
import { CollateralData, Decimal } from "../../types/types";

const useCollateralData = (
  collateralData: { address: string; amount: Decimal }[],
  currentLoans: { address: string; amount: Decimal }[],
  borrowResource: string
) => {
  return useQuery({
    queryKey: [
      "get_collateral_data",
      collateralData,
      borrowResource,
      currentLoans,
    ],
    enabled: !!borrowResource && !!collateralData,
    queryFn: async ({ signal }) => {
      const res = await axios.post<CollateralData>(
        `${BACKEND_URL}/api/markets/borrow-limit-assets`,
        {
          collaterals_borrow_limit: collateralData,
          borrow_resource: borrowResource,
          borrows: currentLoans,
        },
        { signal }
      );

      return res?.data;
    },
  });
};

export default useCollateralData;
