import { Table, TxLoader, Typography } from "../components/atoms";
import Card from "../components/atoms/card";
import useProtocolData from "../components/hooks/useProtocolData";
import {
  ALIAS_TOKENS,
  CGTOKENS_INV,
  ICONS_PATHS,
  TOKENS_ORDER,
} from "../constants";
import { Asset } from "../types/types";
import { formatNumbers } from "../utils";

const Markets = () => {
  const { data, isLoading, error } = useProtocolData();

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center grow">
        <TxLoader />
      </div>
    );
  }

  if (error || !data?.stats) {
    return (
      <div className="flex flex-col items-center justify-center grow">
        <p className="text-white">No data found</p>
      </div>
    );
  }

  const markets = Array.from(Object.entries(data.stats.assets));

  const topLiquidyMarkets = [...markets]
    .sort(([_, a], [__, b]) => b.totalLiquidity.value - a.totalLiquidity.value)
    .slice(0, 3);

  const topBorrowMarkets = [...markets]
    .sort(([_, a], [__, b]) => b.totalBorrow.value - a.totalBorrow.value)
    .slice(0, 3);

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Typography>Total value locked</Typography>
          <Typography size="xl">
            {formatNumbers(data?.stats.totalValueLocked || 0)} $
          </Typography>
        </div>
        <div className="flex flex-col justify-center gap-6 lg:flex-row">
          <Card>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Typography>Total supply</Typography>
                <Typography size="xl">
                  {formatNumbers(data?.stats.totalProtocolSupplied || 0)}$
                </Typography>
              </div>
              <Table
                titles={[
                  `top ${topLiquidyMarkets.length} ${
                    topLiquidyMarkets.length === 1 ? "market" : "markets"
                  }`,
                  "",
                  "",
                ]}
                values={[...topLiquidyMarkets].map(([key, obj]) => {
                  return [
                    {
                      type: "image-text",
                      value: {
                        img: ICONS_PATHS[
                          CGTOKENS_INV[
                            key as keyof typeof CGTOKENS_INV
                          ] as keyof typeof ICONS_PATHS
                        ],

                        text: ALIAS_TOKENS[
                          CGTOKENS_INV[
                            key as keyof typeof CGTOKENS_INV
                          ] as keyof typeof ALIAS_TOKENS
                        ],
                      },
                    },
                    {
                      type: "progress",
                      value: {
                        value: obj?.totalSupply?.value,
                        total: data?.stats?.totalProtocolSupplied,
                      },
                    },
                    {
                      type: "text",
                      value: `${formatNumbers(
                        (obj?.totalSupply?.value * 100) /
                          (data?.stats?.totalProtocolSupplied ?? 1)
                      )}%`,
                    },
                  ];
                })}
                emptyMessage="No markets were found"
              />
            </div>
          </Card>

          <Card>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <Typography>Total Borrow</Typography>
                <Typography size="xl">
                  {formatNumbers(data?.stats.totalProtocolBorrowed || 0)}$
                </Typography>
              </div>

              <Table
                titles={[
                  `top ${topBorrowMarkets.length} ${
                    topBorrowMarkets.length === 1 ? "market" : "markets"
                  }`,
                  "",
                  "",
                ]}
                values={topBorrowMarkets.map(([key, obj]: [string, any]) => {
                  return [
                    {
                      type: "image-text",
                      value: {
                        img: ICONS_PATHS[
                          CGTOKENS_INV[
                            key as keyof typeof CGTOKENS_INV
                          ] as keyof typeof ICONS_PATHS
                        ],

                        text: ALIAS_TOKENS[
                          CGTOKENS_INV[
                            key as keyof typeof CGTOKENS_INV
                          ] as keyof typeof ALIAS_TOKENS
                        ],
                      },
                    },
                    {
                      type: "progress",
                      value: {
                        value: obj?.totalBorrow?.value,
                        total: data?.stats?.totalProtocolBorrowed,
                      },
                    },
                    {
                      type: "text",
                      value: `${formatNumbers(
                        ((obj?.totalBorrow?.value ?? 0) * 100) /
                          (data?.stats?.totalProtocolBorrowed &&
                          data.stats.totalProtocolBorrowed > 0
                            ? data.stats.totalProtocolBorrowed
                            : 1)
                      )}%`,
                    },
                  ];
                })}
                emptyMessage="No markets were found"
              />
            </div>
          </Card>
        </div>
        <Card>
          <Table
            titles={[
              "asset",
              "price",
              "liquidity",
              "total supply",
              "lending apy",
              "total borrow",
              "borrow apy",
            ]}
            values={
              data?.stats.assets
                ? Object.entries(data?.stats.assets)
                    .map(([key, obj]: [string, Asset]) => {
                      const img =
                        ICONS_PATHS[
                          CGTOKENS_INV[
                            key as keyof typeof CGTOKENS_INV
                          ] as keyof typeof ICONS_PATHS
                        ];

                      const text =
                        ALIAS_TOKENS[
                          CGTOKENS_INV[
                            key as keyof typeof CGTOKENS_INV
                          ] as keyof typeof ALIAS_TOKENS
                        ];

                      return { ...obj, text, img };
                    })
                    .sort((a, b) => {
                      const indexA = TOKENS_ORDER.findIndex(
                        (token) => token === a.text
                      );
                      const indexB = TOKENS_ORDER.findIndex(
                        (token) => token === b.text
                      );

                      return indexA - indexB;
                    })
                    .map((obj) => {
                      const usdPrice = parseFloat(obj.totalSupply.amount)
                        ? obj.totalLiquidity.value /
                          parseFloat(obj.totalSupply.amount)
                        : 0;

                      const token = data.tokens.find(
                        (t) => t.address === obj.resource
                      );

                      return [
                        {
                          type: "image-text",
                          value: {
                            img: obj.img,
                            text: obj.text,
                          },
                        },
                        {
                          type: "text",
                          value: `$${formatNumbers(token?.usdPrice ?? 0)}`,
                        },
                        {
                          type: "text",
                          value: `$${formatNumbers(
                            parseFloat(obj.availableLiquidity) * usdPrice
                          )}`,
                        },
                        {
                          type: "text",
                          value: `$${formatNumbers(obj.totalSupply.value)}`,
                        },
                        {
                          type: "text",
                          value: `${formatNumbers(obj.lendingAPY)}%`,
                        },
                        {
                          type: "text",
                          value: `$${
                            obj?.totalBorrow.value < 0.000000001
                              ? 0
                              : formatNumbers(obj.totalBorrow.value)
                          }`,
                        },
                        {
                          type: "text",
                          value: `${formatNumbers(obj.borrowAPY)}%`,
                        },
                      ];
                    })
                : []
            }
            emptyMessage="No assets were found"
          />
        </Card>
      </div>
    </>
  );
};

export default Markets;
