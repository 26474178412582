import { ReactNode } from "react";

export type CardProps = {
  children: ReactNode;
  className?: string;
};

const Card = ({ children, className }: CardProps) => (
  <div className={`py-4 px-6 rounded-xl bg-cardsBg w-full  ${className}`}>
    {children}
  </div>
);

export default Card;
