import Typography from "./typography";

export type ButtonProps = {
  label: string;
  action: (e?: any) => void;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "tertiary" | "max";
  size?: "sm" | "md" | "lg";
  type?: "submit" | "button";
  className?: string;
};

const getButtonVariant = (variant: ButtonProps["variant"]) => {
  switch (variant) {
    case "secondary":
      return "px-4 py-1 border border-textSecondary";

    case "max":
      return "px-4 py-2 rounded-xl border border-textSecondary";

    case "tertiary":
      return "px-4 py-1  border border-textTertiary";

    default:
      return "px-4 py-1 border textPrimary rounded-md";
  }
};

const getButtonSize = (size: ButtonProps["size"]) => {
  switch (size) {
    case "sm":
      return "";
    case "lg":
      return "";

    default:
      return "";
  }
};

const Button = ({
  label,
  action,
  disabled = false,
  variant = "primary",
  size = "md",
  type = "button",
  className,
}: ButtonProps) => (
  <button
    type={type}
    className={`transition-all
    ${disabled ? "cursor-not-allowed" : ""}
    ${getButtonSize(size)} 
    ${getButtonVariant(variant)} 
    ${className} disabled:opacity-40`}
    onClick={action}
    disabled={disabled}
  >
    <Typography
      variant={variant === "max" ? "secondary" : variant}
      weight="bold"
    >
      {label}
    </Typography>
  </button>
);

export default Button;
