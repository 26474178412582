import { ReactNode } from "react";

export type TypographyProps = {
  children: ReactNode;
  variant?: "primary" | "secondary" | "tertiary" | "navbar";
  weight?: "normal" | "semibold" | "bold";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  className?: string;
  style?: object;
  onClick?: (e?: any) => void;
};

const getTextVariant = (variant: TypographyProps["variant"]) => {
  switch (variant) {
    case "secondary":
      return "text-textSecondary";
    case "tertiary":
      return "text-textTertiary";
    case "navbar":
      return "text-textNavBar";

    default:
      return "text-textPrimary";
  }
};

const getTextWeight = (variant: TypographyProps["weight"]) => {
  switch (variant) {
    case "semibold":
      return "font-semibold";
    case "bold":
      return "font-bold";

    default:
      return "font-normal";
  }
};

const getTextSize = (size: TypographyProps["size"]) => {
  switch (size) {
    case "xs":
      return "text-xs";
    case "sm":
      return "text-sm";
    case "lg":
      return "text-lg";
    case "xl":
      return "text-xl";

    default:
      return "text-base";
  }
};

const Typography = (props: TypographyProps) => {
  const {
    children,
    variant = "primary",
    weight = "normal",
    size = "md",
    className,
    style,
  } = props;

  return (
    <span
      className={`align-middle
      ${getTextWeight(weight)}
      ${getTextSize(size)} 
      ${getTextVariant(variant)} 
      ${className}`}
      style={style}
    >
      {children}
    </span>
  );
};

export default Typography;
