import { useState } from "react";
import { borrow } from "../../manifest/manifest";
import { formatNumbers } from "../../utils";
import { Button, Loader, Modal, TxPending, Typography } from "../atoms";
import Spinner from "../atoms/Spinner";
import useCollateralData from "../hooks/useCollateralData";
import useConnect from "../hooks/useConnect";
import useLimits from "../hooks/useLimits";
import useWalletData from "../hooks/useWalletData";
import { useRdtProvider } from "../providers/RdtProvider";
import SliderInput from "./sliderInput";
import { Decimal, Token } from "../../types/types";
import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import { ZERO_DECIMAL } from "../../constants";
import { IoIosWarning } from "react-icons/io";

type BorrowModal = {
  token: Token;
  onClickOutside: (e?: any) => void;
};

const BorrowManageModal = ({ token, onClickOutside }: BorrowModal) => {
  const { data: walletData } = useWalletData();
  const { rdt, address } = useRdtProvider();

  const [isSliderLoading, setIsSliderLoading] = useState(false);

  const [amount, setAmount] = useState<Decimal>(ZERO_DECIMAL);

  const { mutate: connectWallet, isPending: loading } = useConnect();

  const [txResult, setTxResult] = useState<object | undefined>(undefined);
  const [txLoading, setTxLoading] = useState(false);

  const { data, isLoading: collateralDataLoading } = useCollateralData(
    walletData?.nfts[0]?.collateral.resources.map((token) => ({
      address: token.token.address,
      amount: token.asset_amount,
    })) || [],
    walletData?.nfts[0]?.loan.map((token) => ({
      address: token.token.address,
      amount: token.asset_amount,
    })) || [],
    token?.address || ""
  );

  const { data: limits } = useLimits();

  const availableLiquidity = preciseDecimal(
    token.protocolData?.availableLiquidity || "0"
  ).value;

  const maxBorrowAmount =
    preciseDecimal(Number(data?.amount)).value ?? ZERO_DECIMAL;

  let maxBorrow = availableLiquidity;
  if (availableLiquidity.greaterThan(maxBorrowAmount)) {
    maxBorrow = maxBorrowAmount;
  }

  const borrowSimulateOptions =
    !amount || !walletData?.nfts[0].collateral.resources
      ? undefined
      : {
          simulateLimit: {
            borrow: [
              {
                address: token?.address || "",
                amount: amount,
              },
            ],
          },
        };

  const { data: simulateLimits, isLoading } = useLimits(borrowSimulateOptions);

  const borrowLimitUsedPercentage =
    (simulateLimits?.borrowLimitUsedPercentage ?? 0) >= 99.95 &&
    (simulateLimits?.borrowLimitUsedPercentage ?? 0) <= 100
      ? 100
      : simulateLimits?.borrowLimitUsedPercentage;

  const supplyAmount = walletData?.supplies?.find(
    (supply) => supply.address === token?.address
  )?.asset_amount;

  const borrowAmount = walletData?.nfts[0]?.loan?.find?.(
    (loan) => loan.token.address === token.address
  )?.asset_amount;

  const handleBorrow = async () => {
    if (!address) return;
    if (!token) return;
    setTxLoading(true);
    let manifest;
    try {
      if (!walletData?.nfts[0].collateral.cdp) {
        return;
      }
      manifest = await borrow(
        address,
        walletData?.nfts[0].collateral.cdp,
        token?.address,
        amount
      );
    } catch (e) {
      console.error("ERROR tx borrow:", e);
    }
    const result = await rdt.walletApi.sendTransaction({
      transactionManifest: manifest?.value as string,
    });

    if (result.isErr()) {
      console.error("TxError", result);
    }
    setTxResult(result);
    setTxLoading(false);
  };

  return (
    <Modal
      visible
      onClickOutside={
        txLoading || (txResult && !loading)
          ? () => {
              !txLoading && setTxResult(undefined);
              !txLoading && onClickOutside();
            }
          : () => {
              onClickOutside();
              setTxResult(undefined);
            }
      }
    >
      {!walletData?.nfts.length ? (
        <div className="w-[400px] relative text-center px-8 py-4 pt-6 flex flex-col justify-center items-center gap-8">
          <div className="flex flex-col w-full">
            <Typography size="lg" weight="semibold" variant="secondary">
              Warning
            </Typography>
            <Typography size="sm" variant="secondary">
              You must supply at least one asset
            </Typography>
          </div>
          <Button
            label={"Close"}
            action={() => {
              onClickOutside();
              setTxResult(undefined);
            }}
            variant="tertiary"
            className="w-full"
          />
        </div>
      ) : txLoading || (txResult && !loading) ? (
        <TxPending
          loading={txLoading}
          result={txResult}
          onContinue={() => {
            setTxResult(undefined);
            onClickOutside();
          }}
        />
      ) : (
        <div className="w-full lg:max-w-[500px] flex flex-col gap-6">
          <div className="flex flex-col items-center justify-center w-full gap-2 px-8 py-4">
            <img
              alt={token?.name}
              src={token?.imgPath}
              className="object-cover"
              width={40}
              height={40}
            />

            <Typography
              variant="secondary"
              size="sm"
              weight="semibold"
              className="uppercase"
            >
              {token?.name}
            </Typography>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-2 px-8 pb-4">
            <div className="flex items-center justify-between w-full gap-4">
              <Typography
                className="whitespace-nowrap opacity-[60%]"
                variant="secondary"
                size="sm"
              >
                Borrow amount
              </Typography>
              {collateralDataLoading ? (
                <Spinner width={20} height={20} strokeWidth={3} />
              ) : (
                <Typography
                  className="whitespace-nowrap"
                  variant="secondary"
                  size="sm"
                >
                  Borrow Limit {formatNumbers(maxBorrow.toString())}{" "}
                  {token?.name}
                </Typography>
              )}
            </div>

            {walletData != null ? (
              <SliderInput
                maxValue={maxBorrow}
                onChange={(value: Decimal) => setAmount(value)}
                onLoadChange={setIsSliderLoading}
                max
              />
            ) : loading ? (
              <Loader />
            ) : (
              <button
                className="w-full cursor-pointer"
                onClick={() => connectWallet()}
              >
                <Typography variant="navbar">
                  Please connect your wallet
                </Typography>
              </button>
            )}

            <div className="flex flex-col w-full gap-3">
              <Typography
                variant="navbar"
                size="lg"
                className="w-full text-start"
              >
                Borrow Stats
              </Typography>

              <div className="flex flex-col w-full">
                {token?.protocolData?.lendingAPY != null && (
                  <div className="flex items-center justify-between w-full">
                    <Typography variant="secondary">Borrow APY</Typography>
                    <Typography variant="secondary">
                      {formatNumbers(
                        parseFloat(token?.protocolData?.borrowAPY)
                      )}
                      %
                    </Typography>
                  </div>
                )}
                {supplyAmount != null && (
                  <div className="flex items-center justify-between w-full">
                    <Typography variant="secondary">Borrow Balance</Typography>
                    <Typography variant="secondary">
                      $
                      {borrowAmount
                        ? formatNumbers(
                            borrowAmount.mul(token.usdPrice).toString()
                          )
                        : 0}
                    </Typography>
                  </div>
                )}
              </div>

              <Typography
                variant="navbar"
                size="lg"
                className="w-full text-start"
              >
                Borrow Limit
              </Typography>
              <div className="flex flex-col w-full ">
                <div className="flex items-center justify-between w-full">
                  <Typography variant="secondary">Your Borrow Limit</Typography>
                  {isLoading || isSliderLoading ? (
                    <Spinner width={20} height={20} strokeWidth={3} />
                  ) : (
                    <Typography variant="secondary">{`$${formatNumbers(
                      limits?.totalBorrowLimit || 0
                    )} -> $${formatNumbers(
                      simulateLimits?.totalBorrowLimit || 0
                    )}`}</Typography>
                  )}
                </div>
                <div className="flex items-center justify-between w-full">
                  <Typography variant="secondary">Borrow Limit Used</Typography>
                  {isLoading || isSliderLoading ? (
                    <Spinner width={20} height={20} strokeWidth={3} />
                  ) : (
                    <Typography variant="secondary">
                      <div className="flex items-center gap-1">
                        {`${formatNumbers(
                          limits?.borrowLimitUsedPercentage || 0
                        )}% -> ${formatNumbers(
                          borrowLimitUsedPercentage || 0
                        )}%`}
                        {(borrowLimitUsedPercentage || 0) > 95 ? (
                          <IoIosWarning className="fill-red-500 animate-pulse" />
                        ) : (
                          <></>
                        )}
                      </div>
                    </Typography>
                  )}
                </div>
              </div>

              <Button
                action={() => {
                  handleBorrow();
                }}
                label="Borrow"
                variant="tertiary"
                className="w-full"
                disabled={
                  isLoading || amount.eq(ZERO_DECIMAL) || isSliderLoading
                }
              />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BorrowManageModal;
