export type ProgressProps = {
  value: number;
  total: number;
};

const Progress = ({ value, total }: ProgressProps) => (
  <div
    className={`relative w-full min-w-[300px] h-[8px] rounded-md bg-black overflow-hidden`}
  >
    <div
      className={`absolute left-0 top-0 h-full bg-progressPrimary`}
      style={{ width: `${(value * 100) / total}%` }}
    />
  </div>
);

export default Progress;
