import { Oval, OvalProps } from "react-loader-spinner";

export default function Spinner(props?: OvalProps) {
  return (
    <Oval
      visible={true}
      height="50"
      width="50"
      color="#D47CE4"
      strokeWidth="8"
      ariaLabel="oval-loading"
      {...props}
    />
  );
}
