import { Typography } from "../components/atoms";
import backgroundImg from "../assets/images/background.jpg";

const Page404 = () => (
  <div className="flex items-center justify-center w-screen h-screen">
    <img
      src={backgroundImg}
      className="absolute top-0 left-0 w-full h-full -z-10"
      alt=""
    />
    <Typography className="font-bold">Page not found</Typography>
  </div>
);

export default Page404;
