import { configureStore } from "@reduxjs/toolkit";
import walletSlice from "./features/walletSlice";
import landingSlice from "./features/landingSlice";

export const store = configureStore({
  reducer: {
    wallet: walletSlice,
    landing: landingSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // rtd cannot be serialized, so it'll cause an error. We don't want
      // to serialize it so it's okay.
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
