import { ReactNode } from "react";
import { Footer } from "../molecules/index";
import { Navbar } from "./Navbar";

type LayoutProps = {
  children: ReactNode;
  className?: string;
  barClassName?: string;
};

export default function Layout({
  children,
  className,
  barClassName,
}: LayoutProps) {
  return (
    <div className="relative   flex flex-col justify-between min-h-[100dvh]">
      <div
        id="background"
        className="absolute left-0 top-0 w-full h-full -z-10"
      />
      <div className="w-full flex relative flex-col grow">
        <Navbar className={barClassName} />

        <main
          className={`w-full h-full flex flex-col py-12 grow lg:px-12 px-2 ${className}`}
        >
          {children}
        </main>
      </div>
      <Footer />
    </div>
  );
}
