import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import {
  ethIcon,
  hugIcon,
  lsuIcon,
  radixIcon,
  tetherIcon,
  usdcIcon,
  xwbtcIcon,
} from "./assets/crypto";
import {
  HUG,
  LSU,
  POOL_UNIT_HUG,
  POOL_UNIT_LSU,
  POOL_UNIT_USDT,
  POOL_UNIT_XETH,
  POOL_UNIT_XRD,
  POOL_UNIT_XUSDC,
  POOL_UNIT_XWBTC,
  USDT,
  XETH,
  XRD,
  XUSDC,
  XWBTC,
} from "./envs";
import { borrow3Icon, supply1Icon, supply3Icon } from "./assets/multipliers";

type Route = {
  path: string;
  label: string;
};

export const ROUTES: Route[] = [
  { path: "/", label: "RootBoard" },
  { path: "/markets", label: "Markets" },
  /*  { path: "/portfolio", label: "Portfolio" }, */
];

export const TOKENS_ORDER = [
  "XRD",
  "xWBTC",
  "xETH",
  "xUSDC",
  "xUSDT",
  "LSU",
  "HUG",
];

export const POOL_TOKENS = {
  [POOL_UNIT_USDT]: "rtether",
  [POOL_UNIT_XRD]: "rtXRD",
  [POOL_UNIT_XWBTC]: "rxWBTC",
  [POOL_UNIT_XETH]: "rxETH",
  [POOL_UNIT_LSU]: "rLSU",
  [POOL_UNIT_HUG]: "rHUG",
  [POOL_UNIT_XUSDC]: "rxUSDC",
};

export const TOKENS = {
  [XRD]: "XRD",
  [XWBTC]: "xWBTC",
  [XETH]: "xETH",
  [XUSDC]: "xUSDC",
  [USDT]: "tether",
  [LSU]: "LSU",
  [HUG]: "HUG",
  ...POOL_TOKENS,
};

export const TOKENS_ADDRESSES = {
  tether: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "tether")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rtether")[0],
  },
  XRD: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "XRD")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rtXRD")[0],
  },

  xWBTC: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "xWBTC")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rxWBTC")[0],
  },

  xETH: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "xETH")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rxETH")[0],
  },

  LSU: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "LSU")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rLSU")[0],
  },

  HUG: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "HUG")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rHUG")[0],
  },

  xUSDC: {
    //@ts-ignore
    address: Object.entries(TOKENS).find(([val, key]) => key === "xUSDC")[0],
    //@ts-ignore
    rt: Object.entries(TOKENS).find(([val, key]) => key === "rxUSDC")[0],
  },
};

export const ALIAS_TOKENS = {
  tether: "xUSDT",
  rtether: "rxUSDT",
  rtXRD: "rXRD",
  XRD: "XRD",
  xWBTC: "xWBTC",
  xETH: "xETH",
  LSU: "LSU",
  HUG: "HUG",
  xUSDC: "xUSDC",
  rxWBTC: "rxWBTC",
  rxETH: "rxETH",
  rHUG: "rHUG",
  rLSU: "rLSU",
  rxUSDC: "rxUSDC",
};

export const TOKENS_RTOKENS = {
  tether: "rtether",
  XRD: "rtXRD",
  xWBTC: "rxWBTC",
  xETH: "rxETH",
  HUG: "rHUG",
  LSU: "rLSU",
  xUSDC: "rxUSDC",
};

export const TOKENS_RTOKENS_INV = {
  rtether: "tether",
  rtXRD: "XRD",
  rxWBTC: "xWBTC",
  rxETH: "xETH",
  rHUG: "HUG",
  rLSU: "LSU",
  rxUSDC: "xUSDC",
};

export const CGTOKENS = {
  tether: "tether",
  XRD: "radix",
  xWBTC: "bitcoin",
  xETH: "ethereum",
  HUG: "hug",
  LSU: "caviarnine-lsu-pool-lp",
  xUSDC: "usd-coin",
};

export const CGTOKENS_INV = {
  tether: "tether",
  radix: "XRD",
  bitcoin: "xWBTC",
  ethereum: "xETH",
  hug: "HUG",
  "caviarnine-lsu-pool-lp": "LSU",
  "usd-coin": "xUSDC",
};

export const LISTED_TOKENS = [
  { id: "tether", name: "xUSDT" },
  { id: "XRD", name: "XRD" },
  { id: "xWBTC", name: "xWBTC" },
  { id: "xETH", name: "xETH" },
  { id: "HUG", name: "HUG" },
  { id: "LSU", name: "LSU" },
  { id: "xUSDC", name: "xUSDC" },
];

export const MULTIPLIERS_PATH = {
  supply: {
    tether: supply3Icon,
    XRD: supply1Icon,
    xWBTC: supply1Icon,
    xETH: supply1Icon,
    HUG: supply3Icon,
    LSU: supply1Icon,
    xUSDC: supply3Icon,
  },
  borrow: {
    tether: borrow3Icon,
    XRD: borrow3Icon,
    xWBTC: borrow3Icon,
    xETH: borrow3Icon,
    HUG: borrow3Icon,
    LSU: borrow3Icon,
    xUSDC: borrow3Icon,
  },
};

export const ICONS_PATHS: Record<string, string> = {
  tether: tetherIcon,
  rtether: tetherIcon,
  XRD: radixIcon,
  rtXRD: radixIcon,

  xWBTC: xwbtcIcon,
  rxWBTC: xwbtcIcon,

  xETH: ethIcon,
  rxETH: ethIcon,

  HUG: hugIcon,
  rHUG: hugIcon,

  LSU: lsuIcon,
  rLSU: lsuIcon,

  xUSDC: usdcIcon,
  rxUSDC: usdcIcon,
};

export const ZERO_DECIMAL = preciseDecimal(0).value;
