import { PropsWithChildren, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddress } from "../../app/features/walletSlice";
import { RootState } from "../../app/store";

export function RdtProvider({ children }: PropsWithChildren) {
  const { rdt } = useRdtProvider();
  const dispatch = useDispatch();
  useEffect(() => {
    const subscription = rdt.walletApi.walletData$.subscribe((walletData) => {
      if (walletData.persona && walletData.accounts.length === 0) {
        setTimeout(() => {
          rdt.walletApi.updateSharedData();
        }, 500);
        return;
      }

      dispatch(setAddress({ address: walletData.accounts?.[0]?.address }));
    });

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
}

export function useRdtProvider() {
  return useSelector((state: RootState) => state.wallet);
}
