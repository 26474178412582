import { RadixDappToolkit } from "@radixdlt/radix-dapp-toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { DAPP_DEFINITION_ADDRESS, NETWORK_ID } from "../../envs";

export interface WalletState {
  rdt: RadixDappToolkit;
  address?: string;
}

const initialState: WalletState = {
  rdt: RadixDappToolkit({
    dAppDefinitionAddress: DAPP_DEFINITION_ADDRESS,
    networkId: NETWORK_ID,
    applicationName: "Root Finance",
    applicationVersion: "1.0.0",
  }),
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<{ address: string }>) => {
      state.address = action.payload.address;
    },
  },
});

export const { setAddress } = walletSlice.actions;

export default walletSlice.reducer;
