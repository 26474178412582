import { ReactNode } from "react";
import { IoTriangle } from "react-icons/io5";

export type TooltipProps = {
  tooltipChildren: ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  children: ReactNode;
};

const getTooltipPosition = (position: TooltipProps["position"]) => {
  switch (position) {
    case "bottom":
      return "top-8 left-1/2 translate-x-[-50%]";
    case "left":
      return "top-0";
    case "right":
      return "top-0";

    default:
      return "bottom-8 left-1/2 translate-x-[-50%]";
  }
};

const getTooltipIndicatorPosition = (position: TooltipProps["position"]) => {
  switch (position) {
    case "bottom":
      return "-top-2 left-1/2 translate-x-[-50%]";
    case "left":
      return "-top-0 rotate-90";
    case "right":
      return "-top-0 -rotate-90";

    default:
      return "-bottom-2 left-1/2 translate-x-[-50%] rotate-180";
  }
};

const Tooltip = ({
  tooltipChildren,
  position = "top",
  children,
}: TooltipProps) => {
  return (
    <div className="group relative flex justify-center">
      <button className="bg-transparent">{children}</button>
      <span
        className={`absolute bottom-7 scale-0 opacity-0 rounded-lg bg-gray-800 text-xs text-white transition-all shadow-md group-hover:opacity-100 group-hover:scale-100 z-10 ${getTooltipPosition(
          position
        )}`}
      >
        <div className="relative px-4 py-2">
          {tooltipChildren}
          <IoTriangle
            className={`absolute fill-gray-800 shadow-md ${getTooltipIndicatorPosition(
              position
            )}`}
            size={10}
          />
        </div>
      </span>
    </div>
  );
};

export default Tooltip;
