import assert from "assert";
import RootLogo from "./assets/images/root_logo.png";

export function assertNotNull<T>(val: T | undefined | null, msg?: string): T {
  assert(val != null, msg);
  return val;
}

export const BACKEND_URL = assertNotNull(
  process.env.REACT_APP_BE_URL,
  "REACT_APP_BE_URL"
);

export const NETWORK_ID = parseInt(
  assertNotNull(
    process.env.REACT_APP_NETWORK_ID,
    "missing env  REACT_APP_NETWORK_ID"
  )
);

export const DAPP_DEFINITION_ADDRESS = assertNotNull(
  process.env.REACT_APP_DAPP_DEFINITION_ADDRESS,
  "missing env  REACT_APP_DAPP_DEFINITION_ADDRESS"
);

export const LENDING_MARKET_COMPONENT = assertNotNull(
  process.env.REACT_APP_LENDING_MARKET_COMPONENT,
  "missing env  REACT_APP_LENDING_MARKET_COMPONENT"
);
export const LENDING_MARKET_CDP_RESOURCE = assertNotNull(
  process.env.REACT_APP_LENDING_MARKET_CDP_RESOURCE,
  "missing env REACT_APP_LENDING_MARKET_CDP_RESOURCE"
);

export const POOL_UNIT_XRD = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XRD,
  "missing env REACT_APP_POOL_UNIT_XRD"
);
export const POOL_UNIT_USDT = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_USDT,
  "missing env REACT_APP_POOL_UNIT_USDT"
);
export const POOL_UNIT_XWBTC = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XWBTC,
  "missing env REACT_APP_POOL_UNIT_XWBTC"
);
export const POOL_UNIT_XETH = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XETH,
  "missing env REACT_APP_POOL_UNIT_XETH"
);
export const POOL_UNIT_LSU = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_LSU,
  "missing env REACT_APP_POOL_UNIT_LSU"
);
export const POOL_UNIT_HUG = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_HUG,
  "missing env REACT_APP_POOL_UNIT_HUG"
);
export const POOL_UNIT_XUSDC = assertNotNull(
  process.env.REACT_APP_POOL_UNIT_XUSDC,
  "missing env REACT_APP_POOL_UNIT_XUSDC"
);

export const XRD = assertNotNull(
  process.env.REACT_APP_XRD,
  "missing env REACT_APP_XRD"
);
export const USDT = assertNotNull(
  process.env.REACT_APP_USDT,
  "missing env  REACT_APP_USDT"
);
export const XWBTC = assertNotNull(
  process.env.REACT_APP_XWBTC,
  "missing env REACT_APP_XWBTC"
);
export const XETH = assertNotNull(
  process.env.REACT_APP_XETH,
  "missing env REACT_APP_XETH"
);
export const HUG = assertNotNull(
  process.env.REACT_APP_HUG,
  "missing env REACT_APP_HUG"
);
export const LSU = assertNotNull(
  process.env.REACT_APP_LSU,
  "missing env REACT_APP_LSU"
);
export const XUSDC = assertNotNull(
  process.env.REACT_APP_XUSDC,
  "missing env  REACT_APP_XUSDC"
);

export const APP_VISUALIZED_NAME = "Root Finance";

export const APP_VISUALIZED_DESCRIPTION = "Root Receipt";

export const APP_VISUALIZED_LOGO_URL = RootLogo;
