import { useQuery } from "@tanstack/react-query";
import { TOKENS } from "../../constants";
import { getBorrowData, getNonFungibleResources } from "../../utils";
import { useRdtProvider } from "../providers/RdtProvider";
import useProtocolData from "./useProtocolData";
import { preciseDecimal } from "@radixdlt/radix-engine-toolkit";
import usePrices from "./usePrices";

export default function useWalletData() {
  const { rdt, address } = useRdtProvider();
  const { data } = useProtocolData();
  const { data: prices } = usePrices();

  const tokens = data?.tokens;

  return useQuery({
    enabled: !!address && !!tokens && !!prices,
    queryKey: ["wallet-data", address],
    queryFn: async () => {
      if (!address) return;
      if (!tokens) return;

      const data = await rdt.gatewayApi.state.getEntityDetailsVaultAggregated(
        address
      );

      const supplies = data.fungible_resources.items
        .filter((item) =>
          Object.keys(TOKENS).some((token) => token === item.resource_address)
        )
        .map((item) => ({
          ...tokens.find((token) => token.address === item.resource_address)!,
          asset_amount: preciseDecimal(item.vaults.items[0].amount).value,
        }));

      const nfts = getBorrowData(
        await getNonFungibleResources(data.non_fungible_resources, rdt),
        tokens
      );

      console.debug("tokens hold", supplies);
      console.debug("nfts hold", nfts);

      return { supplies, nfts };
    },
    refetchInterval: 30000,
  });
}
