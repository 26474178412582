import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Token } from "../../types/types";

export interface LandingState {
  selectedTokens: Array<Token>;
}

const initialState: LandingState = {
  selectedTokens: [],
};

export const landingSlice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    addCurrency: (state, action: PayloadAction<{ token: Token }>) => {
      const alreadyPresent = state.selectedTokens.find(
        (token) => token.address === action.payload.token.address
      );
      if (alreadyPresent) return;
      state.selectedTokens = [...state.selectedTokens, action.payload.token];
    },
    removeCurrency: (state, action: PayloadAction<{ token: Token }>) => {
      state.selectedTokens = state.selectedTokens.filter(
        (token) => token.address !== action.payload.token.address
      );
    },
    clear: (state) => {
      state.selectedTokens = [];
    },
  },
});

export const { addCurrency, removeCurrency, clear } = landingSlice.actions;

export default landingSlice.reducer;
