import { ReactNode } from "react";

export type InputProps = {
  defaultValue?: string | number;
  placeHolder?: string;
  value?: number | string;
  onChange: (e?: any) => void;
  type?: "text" | "number" | "date";
  disabled?: boolean;
  iconLeft?: ReactNode;
  className?: string;
};

/*   const getInputVariant = (variant: InputProps["variant"]) => {
    switch (variant) {
      case "secondary":
        return "";
  
      default:
        return "";
    }
  };
  
  const getInputSize = (size: InputProps["size"]) => {
    switch (size) {
      case "sm":
        return "";
      case "lg":
        return "";
  
      default:
        return "";
    }
  };
   */
const Input = ({
  defaultValue,
  value,
  placeHolder,
  onChange,
  type,
  disabled = false,
  iconLeft,
  className,
}: InputProps) => (
  <div className="relative w-full">
    <input
      type={type}
      className={`py-2 rounded-xl w-full bg-backgroundInput focus:outline-0 text-textPrimary px-4 ${
        iconLeft ? "pl-10" : ""
      } ${className}`}
      placeholder={placeHolder}
      onChange={(e) => onChange && onChange(e.target.value)}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
    />
    {iconLeft && (
      <div className="absolute top-1/2 left-2 translate-y-[-50%]">
        {iconLeft}
      </div>
    )}
  </div>
);

export default Input;
